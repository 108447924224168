import { MdOutlineMiscellaneousServices } from "react-icons/md";

import { Box, Flex, Text } from "atoms";

const preSales = [
  "Providing product costing to our Associates for further proposal.",
  "Writing proposal on behest of our Associates member.",
  "Preparing Project Proposal and its costing for our Associates, so that he can arrive at a informed decision as to what to be quoted to end user and what is the margins he want to drive out of this deal.",
  "Working with Associates to see that they win the deal and remain competitive in the final count. If they win, we win.",
  "Providing correct, comparative and competitive information to our Associates which helps him win many a deals.",
  "True engagement One-to-One association for registered project cases.",
]

const postSales = [
  "Sourcing of Audio Visual Products for our Associates.",
  "Sourcing planning to see the implementation of the project take place in time.",
  "Working out economical logistics cost for our Associates.",
  "Provide our Associate with Skilled Manpower to work at site for AV projects in India and Overseas.",
  "Freelance AV design and programming for India and Overseas.",
  "Support in customs clearance of any product and resolving any customs related queries for our Associates.",
  "Help prepare a feasible project plan for delivery and implementation for our Associates.",
  "Deployment of Engineers at Customer sites for maintenance of their AV products.",
  "And many more such services that can become your competitive advantage."
]

export const HomeSaleService = () => {
  return (
    <Box
      bg="secondary.500"
      p="4rem"
      id="pre-sale-post-sale"      
    >
      <Box
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Pre Sales Services
          </Text>
          <Flex
            flexDirection="column"
            style={{gap: "2rem"}}
            mt="2rem"
          >
            {preSales.map((point, index) => (
              <Flex
                key={index}
                bg="white"
                p="1rem"
                borderRadius="0.5rem"
                style={{gap: "0.5rem"}}
              >
                <Box
                  color="accent.500"
                  fontSize="1.8rem"
                  mt="0.2rem"
                >
                  <MdOutlineMiscellaneousServices/>
                </Box>
                <Text
                  color="grey.300"
                  fontSize={{xs: "1.4rem", xm: "1.6rem"}}
                  fontWeight="500"
                >
                  {point}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>

        <Box mt="4rem">
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Post Sales Services
          </Text>
          <Flex
            flexDirection="column"
            style={{gap: "2rem"}}
            mt="2rem"
          >
            {postSales.map((point, index) => (
              <Flex
                key={index}
                bg="white"
                p="1rem"
                borderRadius="0.5rem"
                style={{gap: "0.5rem"}}
              >
                <Box
                  color="accent.100"
                  fontSize="1.8rem"
                  mt="0.2rem"
                >
                  <MdOutlineMiscellaneousServices/>
                </Box>
                <Text
                  color="grey.300"
                  fontSize={{xs: "1.4rem", xm: "1.6rem"}}
                  fontWeight="500"
                >
                  {point}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}