import { AiFillCheckCircle } from "react-icons/ai";

import { Box, Flex, Grid, Text } from "atoms";

const values = [
  {
    "label": "Best",
    "description": "must be given in terms of Quality, Price, Technology as well as industry practices and standards of service."
  },
  {
    "label": "Integrity",
    "description": "must be maintained in our dealings and interactions with all our associates and customers."
  },
  {
    "label": "Satisfaction",
    "description": "must be ensured for all who deal with us, through processes that are designed and driven to deliver it."
  },
  {
    "label": "Honesty",
    "description": "must be maintained with all our stakeholders - vendors/associates/members / partners / in-house team members."
  },
  {
    "label": "Trust",
    "description": "must be earned through adhering to commitments, ethical dealings and excellent service deliveries."
  }  
]

const beliefs = [
  {
    "label": "Be what you are",
    "description": "Not giving false impressions to anyone we deal with."
  },
  {
    "label": "Be the best",
    "description": "Continuously striving to improve our products and services."
  },
  {
    "label": "Be professional",
    "description": "Always maintaining professional conduct in all our dealings."
  },
  {
    "label": "Be a team",
    "description": "Enhancing our output through cohesive efforts of all stakeholders."
  },
  {
    "label": "Make things happen",
    "description": "Having a can-do attitude."
  },
  {
    "label": "Learn from all",
    "description": "Constantly attempting to improve and change for the better."
  },
  {
    "label": "Be humble",
    "description": "Lead without a TITLE."
  },
  {
    "label": "Delighting our customer",
    "description": "Aspire to deliver beyond expectations."
  }  
]

export const HomeValueBelief = () => {
  return (
    <Box
      bg="#F8F8F8"
      p="4rem"
      id="values-beliefs"            
    >
      <Box
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Our Values
          </Text>
          <Grid 
            mt="2rem"
            gridTemplateColumns={{xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)"}}
            gridGap="2rem"
          >
            {values.map((value, index) => (
              <Flex
                key={index}
                flexDirection="column"
                width="100%"
                alignItems="center"
                style={{gap: "1rem"}}
                bg="white"
                p="2rem"
                borderRadius="1rem"
                boxShadow="0 0 1rem rgba(0,0,0,0.1)"
              >
                <Box
                  color="success.500"
                  fontSize="2.4rem"
                >
                  <AiFillCheckCircle/>
                </Box>
                <Text
                  fontSize={{xs: "1.8rem", xm: "2.2rem"}}
                  fontWeight="600"
                  color="secondary.500"
                >
                  {value.label}
                </Text>
                <Text
                  fontSize={{xs: "1.4rem", xm: "1.6rem"}}
                  color="grey.300"
                  textAlign="center"
                >
                  {value.description}
                </Text>
              </Flex>
            ))}
          </Grid>
        </Box>

        <Box mt="4rem">
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Our Beliefs
          </Text>
          <Flex 
            mt="2rem"
            flexDirection="column"
            style={{gap: "2rem"}}            
          >
            {beliefs?.map((value, index) => (
              <Flex
                key={index}                
                width="100%"
                alignItems="center"                
                bg="white"
                p="2rem"
                borderRadius="1rem"
                boxShadow="0 0 1rem rgba(0,0,0,0.1)"
              >                                
                <Text
                  fontSize={{xs: "1.4rem", xm: "1.6rem"}}
                  fontWeight="600"
                  textAlign="center"
                  color="accent.500"
                >
                  {value.label}{" : "}
                  <Text
                    as="span"                    
                    color="grey.300"
                    fontWeight="500"
                  > 
                    {value.description}
                  </Text>
                </Text>               
              </Flex>
            ))}
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}