import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { Loader } from "atoms";
import Home from "pages/home";
import NotFound from "pages/not-found";

export const AppRoutes = () => {  
    return (
    <Suspense fallback={<Loader />}>      
      <Routes>
        <Route path="/" exact element={<Home />} />                
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};