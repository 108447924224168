const initState = {      
  error: null,    
  loading: false, 
  message: null,  
}

export const appReducer = (state = initState, action) => {
  switch(action.type){    
    default: return state;
  }  
}