import { Box, Flex, Text } from "atoms";
import { productOfferings } from "utils/constant-data";

const colors = [
  "#B0659B",
  "#5CB49D",
  "#DEA75C",
  "#FF7B78",
  "#0BB8E7",
  "#FF7282",
  "#8D86DD",
  "#78B56F",
  "#B0659B",
  "#A49D64"
]

export const HomeOffering = () => {
  return (
    <Box
      bg="secondary.500"
      p="4rem"
      id="product-offerings"      
    >
      <Box
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Our Product Offerings
          </Text>
        </Box>
        <Flex
          mt="3rem"
          flexWrap="wrap"  
          style={{gap: "2rem"}}        
        >
          {productOfferings?.map((offering, index) => (
            <Box
              key={index}
              width="fit-content"
              p="3rem 1.5rem"
              borderRadius="0.5rem"   
              bg={colors[index%colors.length]}
            >
              <Text
                fontSize="1.6rem"
                fontWeight="500"
                color="white"
              >
                {offering}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  )
}