import { BiMailSend, BiPhoneCall } from "react-icons/bi";

import { Box, Flex, Text } from "atoms";
import { socials } from "utils/constant-data";

export const HeaderContact = () => {
  return (
    <Flex
      width="100vw"
      p="1rem 2rem"
      bg="grey.50"
      justifyContent="space-between"
      alignItems="center"      
    >
      <Flex
        alignItems={{md: "center"}}
        flexDirection={{xs: "column", md: "row"}}
        className="header-contact--flex"       
      >
        <Flex
          alignItems="center"
          color="grey.500"
          style={{
            gap: "0.5rem"
          }}          
          _hover={{
            color: "primary.100"
          }}
          transition="all 0.3s ease-in-out"
        >
          <Box
            fontSize="1.5rem"
            mt="0.4rem"
          >
            <BiPhoneCall/>
          </Box>
          <a
            href="tel:+91-9811475460"
            rel="noopener noreferrer"
            target="_blank"            
          >
            <Text
              fontSize="1.2rem"
            >
              +91-9811475460
            </Text>
          </a>
        </Flex>
        <Flex
          alignItems="center"
          color="grey.500"
          style={{
            gap: "0.5rem"
          }}          
          _hover={{
            color: "primary.100"
          }}
          transition="all 0.3s ease-in-out"
        >
          <Box
            fontSize="1.5rem"
            mt="0.4rem"
          >
            <BiMailSend/>
          </Box>
          <a
            href="mailto:techtronsales1@gmail.com"
            rel="noopener noreferrer"
            target="_blank"            
          >
            <Text
              fontSize="1.2rem"
            >
              techtronsales1@gmail.com
            </Text>
          </a>
        </Flex>
      </Flex>
      <Flex
        alignItems="center"
        style={{
          gap: "1rem"
        }}
      >
        {socials?.map((social, index) => (
          <a
            key={index}
            href={social.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Box
              fontSize="1.5rem"
              color="grey.500"
              _hover={{
                color: "primary.100"
              }}
            >
              {social.Icon}
            </Box>
          </a>
        ))}
      </Flex>
    </Flex>
  )
}