import { Box } from "atoms";
import { HomeAbout, HomeContact, HomeHero, HomeOffering, HomeSaleService, HomeService, HomeValueBelief } from "./molecules";

export const HomePage = () => { 
  return (
    <Box id="home">           
      <HomeHero/>
      <HomeAbout/>
      <HomeValueBelief/>
      <HomeSaleService/>
      <HomeService/>
      <HomeOffering/>
      <HomeContact/>
    </Box>
  );
};