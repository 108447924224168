import { Box, Image, Text } from "atoms";

export const HomeHero = () => {
  return (
    <Box      
      maxHeight={{xs: "23rem", xm: "60rem"}}      
      width="100vw"      
    >
      <Image
        src="/images/landing.jpg"
        alt="home-hero"
        width="100%"
        height="100%"
        objectFit="cover"
        style={{filter: "brightness(0.5)"}}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        color="white"
        width="100%"
        maxWidth={{xs: "30rem", md: "50rem"}}
      >
        <Text
          fontSize={{xs: "2.2rem", md: "2.8rem", xm: "3.6rem"}}
          fontWeight="bold"
          letterSpacing="0.1rem"
          lineHeight="1.2"
        >
          Transform Your Audio Visual & ICT Systems with Techtron International - The Professional System Integrator
        </Text>
      </Box>
    </Box>
  )
}