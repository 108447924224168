import { FiMapPin } from "react-icons/fi";
import { MdConnectWithoutContact } from "react-icons/md";

import { Box, Grid, Text } from "atoms";
import { ContactForm } from "molecules";

export const HomeContact = () => {
  return (
    <Box
      bg="#F8F8F8"
      p="4rem"
      id="contact"            
    >
      <Box
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Get in touch
          </Text>
          <Grid
            mt="3rem"
            gridTemplateColumns={{xs: "repeat(1, 1fr)", xm: "repeat(3, 1fr)"}}            
          >
            <Box
              flexDirection="column"
              width="100%"
              alignItems="center"
              style={{gap: "1rem"}}
              bg="white"
              p="2rem"
              borderRadius="1rem"
              boxShadow="0 0 1rem rgba(0,0,0,0.1)"
              pr="15%"
              display={{xs: "none", xm: "flex"}}
            >
              <Box
                color="accent.500"
                fontSize="3.6rem"                
              >
                <FiMapPin/>
              </Box>
              <Text
                fontSize="1.6rem"
                fontWeight="600"
                color="grey.300"
                textAlign="center"
              >
                Techtron International, Khashra No35, Near Vrindawan Hospital, Shahberi, Greater Noida (W), Disst Gautam Budha Nagar (U.P) 201009 (India)
              </Text>
            </Box>
            <Box
              width={{xm: "120%"}}              
              left={{xm: "-10%"}}
              top={{xm: "-5%"}}
              right={{xm: "-10%"}}
              bottom={{xm: "-5%"}}
              zIndex="2"              
            >
              <Box
                bg="secondary.500"
                borderRadius="1rem"
              >
                <ContactForm/>
              </Box>
            </Box>
            <Box
              flexDirection="column"
              width="100%"
              alignItems="center"
              style={{gap: "1rem"}}
              bg="white"
              p="2rem"
              borderRadius="1rem"
              boxShadow="0 0 1rem rgba(0,0,0,0.1)"
              pl="15%"
              display={{xs: "none", xm: "flex"}}
            >
              <Box
                color="accent.500"
                fontSize="3.6rem"                
              >
                <MdConnectWithoutContact/>
              </Box>
              <Box
                fontSize="1.6rem"
                fontWeight="600"
                color="grey.300"
                textAlign="center"                
              >
                <a
                  href="mailto:techtronsales1@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    _hover={{
                      color: "accent.500"
                    }}
                  >
                  techtronsales1@gmail.com
                  </Text>
                </a>
                <Box my="1rem" />
                <a
                  href="tel:+91-9811475460"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text
                    _hover={{
                      color: "accent.500"
                    }}
                  >
                  +91-9811475460
                  </Text>
                </a>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}