import { FaRegHandPointRight } from "react-icons/fa";

import { Box, Flex, Text } from "atoms";

const points = [
  "A consortium formed by young professionals who have collectively spent almost two decades in the Audio Visual industry in India.",
  "An entity committed to support all system integrators, project collaborators, business managers, freelancers and end users of the Audio Visual industry.",
  "A place where all end users can come to procure their (AV/ICT) product Sourcing needs.",
  '"One stop Solution Provider" for a range of Audio Visual services either in-house or through its network of associates.',
  "In a nutshell, Techtron offers a platform for bringing together all the stakeholders in the Audio Visual as well as Information Communication Technology industry. Regardless of the scale of project or application requirement, Techtron endeavors to be the first stop for all stakeholders to go to – irrespective of what they wish to do – Buy / Sell / Hire / Source and Procure / Design and Consult / Integrate and Implement / Repair and Maintain."
]

export const HomeAbout = () => {
  return (
    <Box
      bg="secondary.500"
      p="4rem"
      id="about"      
    >
      <Flex
        flexDirection={{xs: "column", xm: "row"}}
        justifyContent="space-between"
        style={{gap: "4rem"}}
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
          >
            Who are we
          </Text>
          <Text
            mt="2rem"
            fontSize={{xs: "2.4rem", xm: "3rem"}}
            color="white"
            fontWeight="500"
            lineHeight="1.4"
          >
            We, Techtron International are into the system integration of professional Audio Visual as well as Information Communication Technology Products.
          </Text>
        </Box>
        <Box>
          {points.map((point, index) => (
            <Flex
              key={index}                
              borderBottom="1px solid"
              borderColor="#32467E"
              py="1.5rem"
              style={{gap: "1rem"}}
            >
              <Box
                color="primary.100"
                fontSize={{xs: "1.6rem", xm: "2rem"}}
              >
                <FaRegHandPointRight/>
              </Box>
              <Text
                color="grey.50"                
                fontSize={{xs: "1.4rem", xm: "1.6rem"}}
              >
                {point}
              </Text>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}