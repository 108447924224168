import { Box, Flex, Grid, Image, Text } from "atoms";
import { services } from "utils/constant-data";

export const HomeService = () => {
  return (
    <Box
      bg="#F8F8F8"
      p="4rem"
      id="services"            
    >
      <Box
        maxWidth={{xs: "90vw", xm: "100rem"}}
        mx="auto"
      >
        <Box>
          <Text
            fontSize={{xs: "1.6rem"}}
            color="primary.100"
            textTransform="uppercase"
            fontWeight="700"            
          >
            Our Services
          </Text>
          <Grid 
            mt="2rem"
            gridTemplateColumns={{xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)", xm: "repeat(3, 1fr)"}}
            gridGap="2rem"
          >
            {services?.map((service, index) => (
              <Flex
                key={index}
                width="100%"
                borderLeft="1rem solid"
                borderColor="accent.100"
                borderRadius="0.5rem"
                minHeight="10rem"
                boxShadow="0 0 1rem rgba(0,0,0,0.1)"
              >
                <Box
                  width="60%"
                  p="1.3rem"  
                  bg="white"              
                >
                  <Text
                    fontSize="1.6rem"
                    fontWeight="500"
                    _hover={{color: "accent.500"}}
                    transition="all 0.3s ease-in-out"
                  >
                    {service.name}
                  </Text>
                </Box>
                <Box
                  width="40%"
                >
                  <Image
                    src={service.image}
                    alt={service.name}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    borderRadius="0 0.5rem 0.5rem 0"
                  />
                </Box>
              </Flex>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>    
  )
}