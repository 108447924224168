import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FaUserAlt } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import { BsFillPatchQuestionFill, BsFillTelephoneFill } from "react-icons/bs";

import { Box, Button, Input, Loader } from "atoms";
import Axios from "utils/axios";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid format")
    .required("Email Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),
  name: Yup.string()
    .required("Name Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),  
  phone: Yup.string()
    .required("Phone Required"),
  message: Yup.string().max(2000, "Max 2000 characters allowed"),
});

export const ContactForm = () => {

  const [ loading, setLoading] = useState(false);
  const [ error, setError ] = useState(null);
  const [ message, setMessage ] = useState(null);

  useEffect(()=>{
    if(error){
      toast.error(error)
      setError(null)
    }
    if(message){
      toast.success(message)
      setMessage(null)
    }
  }, [error, message])

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {
      name: values.name,
      email: values.email,
      message: values.message,
      phone: values.phone,
    }   
    setLoading(true) 
    try{
      const { data } = await Axios.post("/techtron/contact", form)
      if(data?.message){
        setMessage(data?.message)
      }
      resetForm()
    }catch(err){
      setError(err.response.data?.error)
    }  
    setSubmitting(false)      
    setLoading(false)
  }

  return (
    <>
      {loading && <Loader/> }      
      <Box 
        maxWidth="60rem" 
        mx="auto"        
        p="4rem"        
      >               
        <Formik
          initialValues={{
            name: "",            
            email: "",
            phone: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors }) => {
            return (
              <Form>
                <Input                  
                  name="name"
                  type="text"
                  placeholder="Name*"                  
                  id="name"
                  icon={<FaUserAlt />}
                  iconColor={errors.name ? "danger.100" : "primary.500"}
                  touched={touched.name}
                  errors={errors.name}                        
                />  
                <Input                  
                  name="email"
                  type="email"
                  placeholder="Email*"                  
                  id="email"
                  icon={<BiEnvelope />}
                  iconColor={errors.email ? "danger.100" : "primary.500"}
                  touched={touched.email}
                  errors={errors.email}                                                      
                />            
                <Input                  
                  name="phone"
                  type="text"
                  placeholder="Phone*"                  
                  id="phone"
                  icon={<BsFillTelephoneFill />}
                  iconColor={errors.phone ? "danger.100" : "primary.500"}
                  touched={touched.phone}
                  errors={errors.phone}                                                      
                />
                <Input                  
                  name="message"
                  type="text"
                  placeholder="Query*"                  
                  id="message"
                  icon={<BsFillPatchQuestionFill />}
                  iconColor={errors.message ? "danger.100" : "primary.500"}
                  touched={touched.message}
                  errors={errors.message}                    
                  textArea  
                  rows={4}                  
                />              
                <Button
                  variant="primary"                  
                  px="2rem"
                  py="1rem"
                  type="submit"                  
                  mt="3rem"
                  fontSize="1.6rem"
                  borderRadius="2rem"
                  loading={loading}
                >                  
                  SEND                 
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </>
  )
}