import { BsFillPhoneFill } from "react-icons/bs";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { GoMailRead } from "react-icons/go";

const socials = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/",
    Icon: <FaFacebookF/>    
  }, 
  {
    name: "Instagram",
    url: "https://www.instagram.com/",
    Icon: <FaInstagram/>
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/",
    Icon: <FaLinkedinIn/>
  }, 
];

const nav = [
  {
    name: "Home",
    url: "home",    
  },
  {
    name: "About",
    url: "about"
  },
  {
    name: "Our Values & Beliefs",
    url: "values-beliefs"
  },  
  {
    name: "Pre Sale & Post Sale",
    url: "pre-sale-post-sale"
  },
  {
    name: "Services",
    url: "services"
  },
  {
    name: "Product Offerings",
    url: "product-offerings"
  },
  {
    name: "Contact",
    url: "contact"
  }
]

const footerLinks =  {
  name: "Useful Links",
  links: [    
    {
      name: "Site Map",
      url: "/site-map"
    },
    {
      name: "Privacy Policy",
      url: "/privacy-policy",      
    },
    {
      name: "Terms & Conditions",
      url: "/terms-and-conditions"
    },
  ]
}

const footerAddress = [
  {
    Icon: <FiMapPin/>,
    value: "Techtron International, Khashra No35, Near Vrindawan Hospital, Shahberi, Greater Noida (W), Disst Gautam Budha Nagar (U.P) 201009 (India)"    
  },
  {
    Icon: <BsFillPhoneFill/>,
    value: "+91-9811475460"
  },
  {
    Icon: <GoMailRead/>,
    value: "techtronsales1@gmail.com"
  }
]

const services = [
  {
    name: "Get your product serviced / repaired from expert (Any AV product).",
    image: "/images/gyps.jpg",
  },
  {
    name: "Optimise your inventory to a minimum level.",
    image: "/images/oyim.jpg",
  },
  {
    name: "Video Conferencing Rentals.",
    image: "/images/vcr.jpg",
  },
  {
    name: "Projector Rentals.",
    image: "/images/pr.jpg",
  },
  {
    name: "Event Management.",
    image: "/images/em.jpg",
  },
  {
    name: "Audio Visual Equipments on rentals.",
    image: "/images/aver.jpg",
  },
  {
    name: "Other AV rentals.",
    image: "/images/aver.jpg",
  },
  {
    name: "Factory trained engineers.",
    image: "/images/fte.jpg",
  },
  {
    name: "Trouble Shootings.",
    image: "/images/ts.jpg",
  },
  {
    name: "Consultancy regarding opening an overseas office in Hong Kong, Dubai and Singapore.",
    image: "/images/cro.jpg",
  }
]

const productOfferings = [
  "Video Conferencing Systems",
  "Interactive Panels 40 inch -98 inch",
  "Audio Conferencing Systems",
  "Display Panels (LCD/LED/Plasma)",
  "LCD/ LED Multimedia Projector",
  "Lighting control System",
  "Motorized Projection Screen",
  'Interactive 17/21/23" LCD tablet monitor',
  "Visual System Presenter",
  "Projector Lift",
  "Audio Video Switcher",
  "Digital Video Recorder",
  "Digital Conference & Interpretation System",
  "Projector spares",
  "Ceiling Mount for Projector",
  "Wall mount for LCD/ LED",
  "Ceiling Document Camera",
  "Touch Control System",
  "Table Microphones",
  "Wireless Microphones",
  "Digital Audio Processor",
  "Matrix Switchers",
  "Amplifiers",
  "Speakers",
  "Cable Cubby",
  "Room Scheduling system",
  "Equipment Rack",
  "Cables & Connectors",
  "Wall Plates",
  "Table Plates",
  "TX/RX Solutions",
  "PA System",
  "Projectors Lamps",
  "Wireless Presentation Device",
]

export {
  socials,
  nav,
  footerLinks,
  footerAddress,
  services,
  productOfferings  
}