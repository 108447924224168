import { useEffect } from "react";

import { Layout } from "organisms";
import SEO from "seo/SEO";
import { HomePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Home = () => {
  
  useEffect(() => {
    scrollToTop(window);   
  }, []);

  return (
    <Layout>      
      <SEO
        title="Techtron International - Professional Audio Visual & ICT System Integrator"
        description="Techtron International specializes in professional audio visual and ICT system integration. We offer a range of products and services including AV solutions, ICT infrastructure, and more. Contact us for your next project."
        keywords="system integration, professional audio visual, information communication technology, products, Techtron International, AV solutions, ICT services, technology integration, communication systems, multimedia solutions, digital signage, network infrastructure, conferencing solutions"        
      /> 
      <HomePage/>
    </Layout>
  );
};

export default Home;
