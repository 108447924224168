import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

import { Box, Flex, Image, Text } from "atoms";
import { footerAddress, footerLinks, socials } from "utils/constant-data";

export const Footer = () => {
  return (
    <Box 
      bg="secondary.500"
      p="2rem"
      color="white"
      as="footer"
    > 
      <Flex          
        flexDirection={{xs: "column", xm: "row"}}      
        alignItems={{xs: "center", xm: "flex-start"}}
        style={{gap: "3rem"}}
        justifyContent={{xm: "space-between"}}
      >
        <Box flexGrow="1">
          <Box height="5rem" width="19.7rem" >
            <Image
              src="/logo.png"
              alt="logo"
              width="100%"
              height="100%"
              borderRadius="0.4rem"
            />
          </Box>
          <Flex
            justifyContent="space-between"
            style={{gap: "1.5rem"}}   
            maxWidth="12rem"       
            mt="2rem"
            mx={{xs: "auto", xm: "unset"}}
          >
            {socials?.map((social, index) => (
              <a
                key={index}
                href={social.url}
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  color="white"
                  fontSize="2rem"
                  _hover={{
                    color: "primary.100"
                  }}
                  transition="all 0.3s ease-in-out"
                >
                  {social.Icon}                
                </Box>
              </a>
            ))}
          </Flex>
        </Box>
        <Flex
          width="100%"
          justifyContent="space-between"          
          style={{gap: "2rem"}} 
          flex="1"         
        >
          {footerAddress && <Box letterSpacing="0.1rem" flex="1">
            <Text
              fontSize="1.6rem"
              fontWeight="600"              
              mb="1.5rem"
            >
              Reach Us
            </Text>
            <Flex
              flexDirection="column"
              style={{gap: "1rem"}}
            >
              {footerAddress?.map((address, index) => (
                <Flex
                  key={index}                  
                  alignItems="start"
                  style={{gap: "1rem"}}
                >
                  <Box
                    fontSize="1.6rem"
                    mt="0.3rem"
                  >
                    {address?.Icon}
                  </Box>
                  <Text
                    fontSize="1.6rem"
                  >
                    {address?.value}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Box>}
          {footerLinks && <Box letterSpacing="0.1rem" flex="1">
            <Text              
              fontSize="1.6rem"
              fontWeight="600"              
              mb="1.5rem"
            >
              {footerLinks?.name}
            </Text>
            <Flex
              flexDirection="column"
              style={{gap: "1rem"}}
            >
            {footerLinks?.links?.map((link, index) => (
              <Box
                key={index}
                _hover={{
                  color: "primary.100"
                }}
                transition="all 0.3s ease-in-out"
              >
                <Link
                  to={link?.url}                
                >
                  <Flex
                    alignItems="center"
                    style={{gap: "0.5rem"}}
                  >
                    <Box fontSize="1.8rem" mt="0.5rem">
                      <MdKeyboardArrowRight/> 
                    </Box>
                    <Text>
                      {link?.name}
                    </Text>
                  </Flex>
                </Link>
              </Box>
            ))}
            </Flex>
          </Box>}
        </Flex>        
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mt="3rem"
        flexDirection={{xs: "column", xm: "row"}}
        style={{gap: "1rem"}}
      >
        <Text>
          © 2023. All Rights Reserved.
        </Text>
        <Text>
          Developed by {" "}
          <Text
            as="span"
            color="primary.100"
          >            
            <a
              href="https://www.pgkonnect.com/"
              target="_blank"
              rel="noreferrer"              
            >
              @PGKONNECT
            </a>
          </Text>
        </Text>        
      </Flex>
    </Box>
  )
}