import MetaTags from "react-meta-tags";

const SEO = ({ title, description, keywords, currentURL, previewImage, type }) => {
  const URL = window.location.href;
  return (
    <MetaTags>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=6"
      />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:site_name" content={title} key="ogsitename" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta property="og:url" content={currentURL || URL} key="ogurl" />
      <meta property="og:image" content={previewImage ? previewImage : "/favicon-seo.png" } key="ogimage" />
      <meta property="og:type" content={type} key="ogtype" />
      
      <title>{title}</title>

      <link href="/manifest.json" rel="manifest" />
      <link href="/favicon-16.png" rel="icon" type="image/png" sizes="16x16" />
      <link href="/favicon-32.png" rel="icon" type="image/png" sizes="32x32" />
      <link href="/favicon-32.png" rel="apple-touch-icon" />
      <meta name="theme-color" content="#051951" />
    </MetaTags>
  );
};

export default SEO;