import { useEffect, useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";

import { Box, Flex, Image, Text } from "atoms";
import { nav } from "utils/constant-data";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

export const Header = ({noNav}) => {

  const [ mobileMenu, setMobileMenu ] = useState(false);
  const [ scroll, setScroll ] = useState();

  const handleScroll = () => {
    if (window.scrollY >= 120) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Box      
      as="header"
      zIndex="100"
    >
      <Box
        position={scroll ? "fixed" : "unset"}
        top={scroll ? "0" : "unset"}
        left={scroll ? "0" : "unset"}
        width="100vw"
      >      
        <Flex                
          width="100vw"
          alignItems="center"
          justifyContent="space-between"
          p="1rem 2rem"
          bg="secondary.500"          
        >
          <Box
            height="4rem"
            width="15.7rem"
          >
            <Image
              src="/logo.png"
              alt="logo"
              width="100%"
              height="100%"
              borderRadius="0.4rem"
            />
          </Box>
          <Box
            as="nav"
            display={{xs: "none", xm: "flex"}}
            style={{
              gap: "2rem"
            }}          
          >
            {noNav ? <NavLink
              to={"/"}                   
              activeClassName="active"             
            >
              <Text
                color="white"                  
                fontSize="1.5rem"
                fontWeight="500"
                _hover={{
                  color: "primary.100"
                }}
                transition="all 0.3s ease-in-out"
              >
                Home
              </Text>
            </NavLink> : nav?.map((item, index) => ( 
              <>
                {item?.type === "link" ? <NavLink
                  key={index}             
                  to={item.url}                   
                  activeClassName="active"             
                >
                  <Text
                    color="white"                  
                    fontSize="1.5rem"
                    fontWeight="500"
                    _hover={{
                      color: "primary.100"
                    }}
                    transition="all 0.3s ease-in-out"
                  >
                    {item.name}
                  </Text>
                </NavLink> : <Link   
                  key={index}             
                  to={item.url} 
                  smooth 
                  spy
                  activeClassName="active" 
                  offset={-30}            
                >
                  <Text
                    color="white"                  
                    fontSize="1.5rem"
                    fontWeight="500"
                    _hover={{
                      color: "primary.100"
                    }}
                    transition="all 0.3s ease-in-out"
                  >
                    {item.name}
                  </Text>
                </Link>}
              </>                     
            ))}
          </Box>
          <Box
            display={{xm: "none"}}
            color="white"
            fontSize="3rem"          
            cursor="pointer"
            _hover={{
              color: "primary.100"
            }}
            transition="all 0.3s ease-in-out"
            mt="0.4rem"  
            onClick={() => setMobileMenu(!mobileMenu)}                  
          >
            <HiMenuAlt3/>
          </Box>
        </Flex>
        <Box        
          as="nav"
          display={{xs: mobileMenu ? "flex" : "none", xm: "none"}}
          flexDirection="column"        
          bg="secondary.500"
          p="2rem"
          transition="all 0.3s ease-in-out"
          style={{
            gap: "2rem"
          }}
        >
          {noNav ? <NavLink
              to={"/"}                   
              activeClassName="active"             
            >
              <Text
                color="white"                  
                fontSize="1.5rem"
                fontWeight="500"
                _hover={{
                  color: "primary.100"
                }}
                transition="all 0.3s ease-in-out"
              >
                Home
              </Text>
            </NavLink>: nav?.map((item, index) => (
            <Link
              key={index}
              to={item.url}
              activeClassName="active"
              onClick={() => setMobileMenu(false)}
              smooth
              spy
              offset={-35}
            >
              <Text
                color="white"
                fontSize="1.5rem"
                fontWeight="500"
                _hover={{
                  color: "primary.100"
                }}
                transition="all 0.3s ease-in-out"                            
              >
                {item.name}
              </Text>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

Header.defaultProps = {
  noNav: false
}